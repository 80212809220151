<template>
  <v-menu v-if="!$vuetify.breakpoint.smAndDown" v-model="menuEscritorio" :close-on-content-click="false" offset-y
          transition="scale-transition" min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text x-large class="text-capitalize text-h4 px-0 mx-0">{{ fechaParaMostrar }}<v-icon>mdi-menu-down</v-icon></v-btn>
    </template>
    <v-date-picker :value="value" @input="menuEscritorio = false" :allowed-dates="allowedDates" @change="guardarFecha"
                   locale="es" />
  </v-menu>

  <v-dialog v-else ref="dialog" v-model="dialogMovil" :return-value.sync="fechaEnDialog" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <h2 v-bind="attrs" v-on="on" class="ml-3">{{ fechaParaMostrar }}<v-icon>mdi-menu-down</v-icon></h2>
    </template>
    <v-date-picker v-model="fechaEnDialog" scrollable :allowed-dates="allowedDates" locale="es">
      <v-spacer />
      <v-btn text color="primary" @click="dialogMovil = false">Cancelar</v-btn>
      <v-btn text color="primary" @click="getFechaDialog">Aceptar</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
  import FechaService from "../../services/FechaService";

  export default {
    name: "SelectFecha",
    props: {
      value: String
    },
    data () {
      return {
        fechaEnDialog: this.value,
        menuEscritorio: false,
        dialogMovil: false
      }
    },
    watch: {
      value (val) {
        this.fechaEnDialog = val
      }
    },
    computed: {
      fechaParaMostrar: (vm) => FechaService.getFechaString(vm.value, 'DD MMMM YYYY')
    },
    methods: {
      allowedDates: val => FechaService.enRangoDentroDeFechaLimite(val),
      guardarFecha (fecha) {
        this.$emit('input', fecha)
      },
      getFechaDialog () {
        this.$refs.dialog.save(this.fechaEnDialog);
        this.guardarFecha(this.fechaEnDialog);
      }
    }
  }
</script>

<style scoped>

</style>
