<template>
  <cargar-mapa :key="keyMapa" :configMapa="configMapa" :api-key="apiKey">
    <template slot-scope="{ google, map }">
      <marcadores
        v-for="(pedido, index) in pedidos"
        :key="index"
        :marker="getMarker(pedido)"
        :google="google"
        :map="map"
      />
    </template>
  </cargar-mapa>
</template>

<script>
  import CargarMapa from "./CargarMapa";
  import Marcadores from "./Marcadores";
  import { configMapa } from "../../config/configMapa";
  import { dark } from "../../config/stylesMapa";
  import AuthConst from "../../config/AuthConst";
  import MapaService from "../../services/MapaService";

  export default {
    name: "Mapa",
    components: { CargarMapa, Marcadores},
    props: {
      pedidos: { type: Array, default: () => [] }
    },
    data () {
      return {
        apiKey: AuthConst.API_KEY_GOOGLE_MAPS
      }
    },
    computed: {
      keyMapa () {
        return (this.pedidos.length > 0 ? (this.pedidos.length + '-' + this.pedidos[0].id) : 0) + this.$vuetify.theme.dark
      },
      configMapa() {
        return { ...configMapa, center: this.mapCenter, styles: this.$vuetify.theme.dark ? dark : [] };
      },
      mapCenter() {
        return this.pedidos.length > 0 ? MapaService.getCentro(this.pedidos) : AuthConst.COORDENADAS_VJP_ALMACEN;
      }
    },
    methods: {
      getMarker (pedido) {
        return MapaService.getMarker(pedido);
      }
    }
  }
</script>

<style scoped>

</style>
