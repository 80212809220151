<template>
    <v-card style="border-radius: 40px;" elevation="0" class="pa-2">
        <foto v-if="!sinFoto" :id="unidad.id" type="unidad" updatable />

        <v-card-title>{{ unidad | unidad }}</v-card-title>

        <v-card-text>
            <span class="font-weight-bold">{{ unidad.placas }}</span>
            <span v-if="unidad.tipoPlacas">&nbsp;({{ unidad.tipoPlacas }})</span>
            <br>
            <h4>{{ unidad.combustible }}</h4>
        </v-card-text>
    </v-card>
</template>

<script>
    import Foto from "../General/Foto";
    export default {
        name: "Unidad",
        components: {Foto},
        props: {
            unidad: { type: Object, required: true },
            sinFoto: { type: Boolean, default: false }
        }
    }
</script>

<style scoped>

</style>
