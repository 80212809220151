import AuthConst from '../config/AuthConst';
import http from "./http";
import MapaService from "./MapaService";

const getPedidosByEstatusAndZona = (estatus, zona) => {
  let url = AuthConst.BASE_URL + '/vi/ventas/estatus/' + estatus + '/zona/' + zona + '.json';
  return http.get(url)
};

const getZonasConPedidosPorEnviar = (zonas = [], callbackFiltro) => {
  return new Promise(async (resolve, reject) => {
    try {
      let zonasPedidos = [];

      for (let zonaRuta of zonas) {
        // Obtiene pedidos Por enviar por zona
        let pedidos = (await getPedidosByEstatusAndZona('Por enviar', zonaRuta.zona))
          .filter(pedido => pedido.paqueteria === 'VJP') // Filtra los pedidos con paquetería "VJP"
          .map(pedido => getPedidoSimplificado(pedido)); // Guarda los pedidos simplificados

        if (!!(callbackFiltro)) { // Si hay filtro, lo aplica
          pedidos = pedidos.filter(pedido => callbackFiltro(pedido.id, zonaRuta.pedidos));
        }
        // Si hay pedidos de la zona, los agrega
        if (pedidos.length > 0) zonasPedidos.push({zona: zonaRuta.zona, pedidos});
      }

      resolve(zonasPedidos)
    } catch (e) {
      reject(e)
    }
  })
};

const getZonasConPedidosPorEnviarDisponibles = (zonas, ruta) => {
  let pedidosAsignados = getPedidosAsignados(ruta);
  let zonasPedidosDisponibles = [];

  for (let zonaRuta of zonas) {
    let pedidos = zonaRuta.pedidos.filter(pedido => !pedidosAsignados.some(pedidoAsignado => pedidoAsignado.id === pedido.id));
    if (pedidos.length > 0) zonasPedidosDisponibles.push({...zonaRuta, pedidos}); // Si hay pedidos de la zona, los agrega
  }

  return zonasPedidosDisponibles;
};

const getZonasConPedidosPorEnviarDeOperador = (zonas) => {
  return getZonasConPedidosPorEnviar(zonas, (idPedido, pedidos) => pedidos.some(pedido => pedido.id === idPedido));
};

const getPedidosAsignados = (ruta) => {
  // Obtiene lista de todos los pedidos que han sido asignados el día de hoy.
  return ruta.operadores.reduce((pedidos, operador) => pedidos.concat(getPedidosAsignadosOperador(operador.zonas)), [])
};

const getPedidosAsignadosOperador = (zonas) => {
  // Obtiene lista los pedidos que han sido asignados al operador el día de hoy.
  return zonas.reduce((pedidosZona, zona) => pedidosZona.concat(zona.pedidos), [])
};

const updateEstatusEnVIS = (idPedido, estatus) => {
  let url = AuthConst.BASE_URL + '/vi/' + idPedido + '/editar/estatus/' + estatus + '.json';
  return http.post(url)
};

const getColorEstatus = (estatus) => {
  const coloresEstatus = AuthConst.COLORES_ESTATUS;
  return coloresEstatus[estatus] || '#424242';
};

function getPedidoSimplificado(pedido) {
  let {id, estatus, cliente} = pedido;
  return {
    id,
    estatus,
    cliente,
    comentarios: [],
    distancia: MapaService.getDistancia(MapaService.getMarker(pedido).position)
  };
}

export default {
  getZonasConPedidosPorEnviar, getZonasConPedidosPorEnviarDisponibles, getZonasConPedidosPorEnviarDeOperador,
  getPedidosAsignados, getPedidosAsignadosOperador, updateEstatusEnVIS, getColorEstatus
}
