<template>
  <wrapper>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab" hide-slider active-class="sub-menu" background-color="transparent"
                :centered="$vuetify.breakpoint.smAndDown">
          <v-tab class="text-capitalize">Operadores</v-tab>
          <v-tab class="text-capitalize">Unidades</v-tab>
        </v-tabs>
      </v-col>
      <v-col v-if="tab === 0" cols="12">
        <v-row>
          <v-col cols="12" sm="12" md="6" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'">
            <select-fecha v-model="fecha"/>
            <v-btn icon large color="primary" @click="$router.go()">
            <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6"
                 :style="$vuetify.breakpoint.smAndDown ? '' : 'display: flex;align-items: center; justify-content: flex-end;'"
                 :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''">
            <v-btn x-small outlined rounded color="primary">Sin unidad</v-btn>
            <span class="mx-2 grey--text">|</span>
            <v-btn x-small outlined rounded color="primary">Sin ruta</v-btn>
            <v-btn class="mx-2" x-small outlined rounded color="primary">En ruta</v-btn>
          </v-col>
        </v-row>
      </v-col>

      <template v-if="tab === 0">
        <v-col v-for="(operador, indexOperador) in $operadores" :key="indexOperador" cols="12" sm="12" md="4">
          <operador :operador="operador" :zonas-con-pedidos-disponibles="zonasConPedidosDisponibles" :fecha="fecha"
                    :key="fecha" :zonas-operador-asignadas="getZonasOperador(operador.id)"
                    @guardar-ruta="aplicarPedidosAsignados"/>
        </v-col>
      </template>
      <template v-if="tab === 1">
        <v-col v-for="(unidad, indexUnidad) in $flotilla" :key="indexUnidad" cols="12" sm="12" md="4">
          <unidad :unidad="unidad"/>
        </v-col>
      </template>
    </v-row>
  </wrapper>
</template>

<script>
  import Operador from "../Operador/Operador";
  import Unidad from "../Flotilla/Unidad";
  import OperadoresService from "../../services/OperadoresService";
  import FlotillaService from "../../services/FlotillaService";
  import RutasService from "../../services/RutasService";
  import PedidosService from "../../services/PedidosService";
  import FechaService from "../../services/FechaService";
  import SelectFecha from "../General/SelectFecha";

  let zonas = [];

  export default {
    name: "Administrador",
    components: {SelectFecha, Unidad, Operador},
    data() {
      return {
        zonasConPedidosDisponibles: [],
        mostrarOperadores: true,
        mostrarFlotilla: false,
        tab: null,
        fecha: FechaService.getFechaString()
      }
    },
    async created() {
      this.$loader = true
      try {
        this.$rutas = await RutasService.getRutasDesdeAdministrador(this.fecha);
        this.$operadores = await OperadoresService.getOperadores();
        this.$flotilla = await FlotillaService.getFlotilla();

        zonas = await PedidosService.getZonasConPedidosPorEnviar(RutasService.getZonasConClave());
        this.zonasConPedidosDisponibles = PedidosService.getZonasConPedidosPorEnviarDisponibles(zonas, this.$rutas);
      } catch (e) {
        this.$showAlerta('600', 'error', 'mdi-alert-circle',
          'No fue posible cargar las rutas, por favor inténtelo de nuevo más tarde.', true);
      }
      this.$loader = false
    },
    watch: {
      async fecha(val) {
        this.$loader = true;
        try {
          this.$rutas = await RutasService.getRutasDesdeAdministrador(val);
          this.zonasConPedidosDisponibles = PedidosService.getZonasConPedidosPorEnviarDisponibles(zonas, this.$rutas);
        } catch (e) {
          this.$router.go();
        }
        this.$loader = false;
      }
    },
    methods: {
      aplicarPedidosAsignados() {
        this.zonasConPedidosDisponibles = PedidosService.getZonasConPedidosPorEnviarDisponibles(zonas, this.$rutas);
      },
      getZonasOperador(idOperador) {
        if (this.$rutas.operadores) {
          let index = this.$getIndex(this.$rutas.operadores, 'id', {id: idOperador});
          if (index >= 0)
            return this.$rutas.operadores[index];
        }

        return {id: idOperador, zonas: []};
      }
    }
  }
</script>

<style scoped>
  .sub-menu {
    border-radius: 30px !important;
  }
</style>
