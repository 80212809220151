<template>
    <administrador v-if="$usuario.administrador || ( $usuario.gestor && !$verComoOperador )"/>
    <usuario v-else/>
</template>

<script>
    import Administrador from "./Dashboards/Administrador";
    import Usuario from "./Dashboards/Usuario";

    export default {
        name: "Dashboard",
        components: {Usuario, Administrador},
    }
</script>

<style scoped>

</style>
