<template>
    <v-row justify="center" align="center" class="mt-4">
        <v-col cols="12" sm="10" md="8" lg="6">
            <v-img :src="require('../../assets/entrega-completa.svg')"/>
        </v-col>
        <v-col cols="12" sm="12" class="text-center">
            <h1 class="grey--text">{{ titulo }}</h1>
        </v-col>
        <v-col cols="12" sm="12" md="6">
            <v-divider/>
        </v-col>
        <v-col cols="12" style="display: grid;justify-content: center;align-items: center;">
            <slot/>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "FullState",
        props: {
            titulo: { type: String, required: true } },
    }
</script>

<style scoped>

</style>
