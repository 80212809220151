<template>
  <v-row>
    <v-col cols="6" class="text-center">
      <h3 class="text--disabled">Entregados {{ pedidosEntregados.length }}</h3>
    </v-col>
    <v-col cols="6" class="text-center">
      <h3 class="text--disabled">Cancelados {{ pedidosCancelados.length }}</h3>
    </v-col>
    <v-col v-for="(zona, index) in ruta.zonas" :key="index" cols="12">
      <zona-operador :zona="zona" es-consulta />
    </v-col>
  </v-row>
</template>

<script>
    import RutasService from "../../services/RutasService";
    import ZonaOperador from "../Zonas/ZonaOperador";

    export default {
        name: "ResumenRutaTerminada",
        components: {ZonaOperador},
        props: {
            ruta: { type: Object, required: true }
        },
        computed: {
            pedidosEntregados () {
                if (this.ruta)
                    return RutasService.getPedidosRuta(this.ruta).filter(pedido => pedido.estatus === 'Entregada');

                return [];
            },
            pedidosCancelados () {
                if (this.ruta)
                    return RutasService.getPedidosRuta(this.ruta).filter(pedido => pedido.estatus === 'Entrega cancelada');

                return [];
            }
        }
    }
</script>

<style scoped>

</style>
