<template>
  <v-menu bottom left min-width="250">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon v-bind="attrs" v-on="on">
        <v-chip color="#424242" small dark class="font-weight-bold">{{ pedidos.length }}</v-chip>
      </v-btn>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-title>Total de pedidos</v-list-item-title>
        <v-list-item-icon class="mx-0">
          <v-chip color="#424242" small dark class="font-weight-bold">{{ pedidos.length }}</v-chip>
        </v-list-item-icon>
      </v-list-item>

      <v-divider />

      <template v-for="(item, i) in estatusLista">
        <v-list-item v-if="item.noPedidos" :key="i">
          <v-list-item-title>{{ item.estatus }}</v-list-item-title>
          <v-list-item-icon class="mx-0">
            <v-chip :color="item.color" small dark class="font-weight-bold">{{ item.noPedidos }}</v-chip>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import AuthConst from "../../config/AuthConst";

  export default {
    name: "ContadorPedidos",
    props: { pedidos: { type: Array, default: () => [] } },
    computed: {
      estatusLista () {
        const coloresEstatus = AuthConst.COLORES_ESTATUS
        return  Object.keys(coloresEstatus)
          .map(estatus => ({
            estatus,
            color: coloresEstatus[estatus],
            noPedidos: getLengthPedidosPorEstatus(this.pedidos, estatus)
          }));
      }
    }
  }

  function getLengthPedidosPorEstatus(pedidos, estatus) {
    return pedidos.filter(pedido => pedido.estatus === estatus).length
  }
</script>

<style scoped>

</style>
