<template>
  <div>
    <div class="mapa" ref="mapa"></div>

    <template v-if="!!(google) && !!(map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
  import { Loader } from "@googlemaps/js-api-loader";

  export default {
    name: "CargarMapa",
    props: { configMapa: Object, apiKey: String},
    data() {
      return {
        google: null,
        map: null
      };
    },
    mounted() {
      let vm = this;

      const loader = new Loader({
        apiKey: vm.apiKey,
        version: "weekly",
        libraries: ["places"]
      });

      loader.load()
        .then(() => {
          vm.google = google
          vm.map = new vm.google.maps.Map(vm.$refs.mapa, vm.configMapa);
        })
        .catch(e => (console.log(e)));
    }
  }
</script>

<style scoped>
  .mapa {
    width: 100%;
    height: 100%;
  }
</style>
