<template>
  <v-card outlined :disabled="!estaEscaneado" class="mb-3">
    <v-card-title class="text-subtitle-1 mb-0 pb-0 font-weight-bold">{{ pedido.id }}
      <v-spacer/>
      <v-menu v-if="!esConsulta" bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$emit('abrir-detalles', pedido)">
            <v-list-item-title>Detalles</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('abrir-comentarios', pedido)">
            <v-list-item-title>Comentarios</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="pedido.estatus === 'En ruta' || pedido.estatus === 'Entrega programada'"
                       @click="$emit('abrir-para-cancelar', pedido)">
            <v-list-item-title>Cancelar envío</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-card-subtitle class="my-0 py-0">
      <h4 class="font-weight-regular caption">{{ pedido.cliente.colonia }}</h4>
    </v-card-subtitle>

    <v-card-actions class="mt-0 pt-0 mx-2">
      <chip-estatus :estatus="pedido.estatus"/>
      <v-spacer/>

      <template v-if="!esConsulta">
        <v-btn v-if="pedido.estatus === 'En ruta'" color="primary" icon @click="$emit('mostrar-mapa', pedido)">
          <v-icon>mdi-google-maps</v-icon>
        </v-btn>

        <v-btn v-if="pedido.estatus === 'Entrega programada'" color="primary" icon @click="$emit('abrir-para-iniciar-ruta', pedido)">
          <v-icon>mdi-truck-delivery</v-icon>
        </v-btn>

        <v-btn v-if="pedido.estatus === 'En ruta'" color="warning" icon @click="$emit('abrir-pausar-entrega', pedido)">
          <v-icon>mdi-pause</v-icon>
        </v-btn>

        <v-btn v-if="pedido.estatus === 'En ruta'" color="success" icon @click="$emit('abrir-para-escanear-y-entregar', pedido)">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
    import ChipEstatus from "./ChipEstatus";
    export default {
        name: "PedidoOperador",
        components: {ChipEstatus},
        props: {
            pedido: { type: Object, required: true },
            esConsulta: { type: Boolean, default: false },
            estaEscaneado: { type: Boolean, default: false }
        }
    }
</script>

<style scoped>

</style>
