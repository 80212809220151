<template>
  <v-card style="border-radius: 20px;">

    <v-card-title>
      {{ $getZonaNombre( zona.zona ) }}
      <v-spacer/>
      <v-bottom-navigation v-if="!esConsulta" width="140px" class="ma-0 pa-0">
        <v-btn v-if="!zona.escaneados" @click="$emit('abrir-escaneo')">
          <span class="primary--text v-btn-login">Escanear</span>
          <v-icon class="primary--text v-btn-login">mdi-qrcode-scan</v-icon>
        </v-btn>

        <v-btn @click="$emit('mostrar-mapa')">
          <span class="primary--text">Mapa</span>
          <v-icon class="primary--text">mdi-google-maps</v-icon>
        </v-btn>
      </v-bottom-navigation>

    </v-card-title>
    <v-card-subtitle class="font-weight-bold">{{ $getZonaMunicipio( zona.zona ) }}</v-card-subtitle>
    <v-divider class="mx-2"/>
    <v-card-text>
      <slot name="pedido" v-for="(pedido, indexPedido) in zona.pedidos" :pedido="pedido">
        <pedido-operador :key="indexPedido" :pedido="pedido" es-consulta :esta-escaneado="zona.escaneados" />
      </slot>
    </v-card-text>
  </v-card>
</template>

<script>
    import PedidoOperador from "../Pedidos/PedidoOperador";
    export default {
        name: "ZonaOperador",
        components: {PedidoOperador},
        props: {
            zona: { type: Object, required: true },
            esConsulta: { type: Boolean, default: false }
        }
    }
</script>

<style scoped>

</style>
