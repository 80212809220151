<template>
  <v-chip :color="color" small dark class="font-weight-bold">{{ estatus }}</v-chip>
</template>

<script>
  import PedidosService from "../../services/PedidosService";

  export default {
    name: "ChipEstatus",
    props: { estatus: { type: String, required: true } },
    computed: {
      color () {
        return PedidosService.getColorEstatus(this.estatus)
      }
    }
  }
</script>

<style scoped>

</style>
