<script>
  export default {
    name: "Marcadores",
    props: {
      google: { type: Object, required: true },
      map: { type: Object, required: true },
      marker: { type: Object, required: true }
    },
    mounted() {
      const content = '<div id="content" style="color: black;">' + this.marker.title + '</div>';
      const infoWindow = new this.google.maps.InfoWindow({ content });

      const marker = new this.google.maps.Marker({
        icon: getIcon(this.marker.color),
        position: this.marker.position,
        marker: this.marker,
        map: this.map,
        title: this.marker.title,
        optimized: false
      });

      marker.addListener("click", () => (infoWindow.open(marker.getMap(), marker)));
    },
    render() {}
  }

  function getIcon(color) {
    return {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#757575',
      strokeWeight: 2,
      scale: 1
    }
  }
</script>
