let logError = (error) => {
    if (process.env.NODE_ENV === 'production') {
        if (error.message !== 'Request failed with status code 404') {
            // console.log(error)
        }
    } else {
        console.log(error)
    }
}

let logService = { logError }

export default logService

