<template>
  <div>
    <v-card v-for="(zonaPedidos, index) in zonasConPedidosDisponiblesOperador" :key="index" class="my-2"
            style="border-radius: 20px;" :disabled="zonaPedidos.escaneados">
      <v-card-title>
        {{ $getZonaNombre(zonaPedidos.zona) }}
        <v-spacer/>
        <v-checkbox @change="marcarPedidosZona(zonaPedidos, $event)"/>
      </v-card-title>

      <v-card-subtitle>
        Pedidos seleccionados: <strong>{{ rutaOperador[zonaPedidos.zona].pedidos.length }} / {{ zonaPedidos.pedidos.length }}</strong>
      </v-card-subtitle>

      <v-card-subtitle v-if="zonaPedidos.escaneados" class="error--text">
        ¡No puede editar una zona que ya ha sido despachada!
      </v-card-subtitle>

      <v-divider class="mx-2"/>

      <v-card-text>
        <v-list two-line>
          <v-list-item-group v-model="rutaOperador[zonaPedidos.zona].pedidos" multiple active-class="primary--text">
            <template v-for="(pedido, indexPedido) in zonaPedidos.pedidos">
              <v-list-item :key="index + '-pedido-' + indexPedido" :value="pedido" @click="generarRutas">

                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>Pedido: {{ pedido.id }}</v-list-item-title>
                    <v-list-item-subtitle>{{ pedido.cliente.municipio }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox :input-value="active" :true-value="pedido" readonly/>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider v-if="indexPedido < zonaPedidos.pedidos.length - 1" :key="index + '-divider-' + indexPedido"/>
            </template>

          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import RutasService from "../../services/RutasService";

  export default {
    name: "AsignacionRuta",
    props: {
      value: {type: Array},
      zonasConPedidosDisponibles: {type: Array, required: true}
    },
    data() {
      return {
        rutaOperador: getRutaOperador(this.value),
        zonasConPedidosDisponiblesOperador: getZonasConPedidosDisponiblesParaOperador(this.value, this.zonasConPedidosDisponibles)
      }
    },
    methods: {
      marcarPedidosZona(zonaPedidos, value) {
        this.$set(this.rutaOperador[zonaPedidos.zona], 'pedidos', value ? zonaPedidos.pedidos : []);
        this.generarRutas();
      },
      generarRutas() {
        this.$nextTick(() => {
          let zonas = getZonas(this.rutaOperador);
          this.$nextTick(() => (this.$emit('input', zonas)));
        })
      }
    }
  }

  function getRutaOperador(zonasConPedidosAsignados) {
    let rutaOperador = RutasService.getZonasConClave()
      .reduce((ruta,zona)=> (ruta[zona.zona] = { escaneados: false, pedidos: [] } , ruta),{});
    zonasConPedidosAsignados.forEach(zona => (rutaOperador[zona.zona] = { ...zona }));
    return rutaOperador;
  }

  function getZonasConPedidosDisponiblesParaOperador(zonasConPedidosAsignados, zonasConPedidosDisponibles) {
    let zonas = RutasService.getZonasConClave()
    for (let zona of zonas) {
      let pedidosAsignados = getPedidosZona(zona.zona, zonasConPedidosAsignados)
      let pedidosDisponibles = getPedidosZona(zona.zona, zonasConPedidosDisponibles)
      zona.pedidos = [...new Set([...pedidosAsignados, ...pedidosDisponibles])]
      zona.escaneados = getEscaneados(zona.zona, zonasConPedidosAsignados)
    }
    return zonas.filter(zona => zona.pedidos.length > 0)
  }

  function getPedidosZona(clave, zonas) {
    let index = zonas.findIndex(zona => zona.zona === clave)
    if (index >= 0)
      return zonas[index].pedidos

    return []
  }

  function getEscaneados(clave, zonas) {
    let index = zonas.findIndex(zona => zona.zona === clave)
    if (index >= 0)
      return zonas[index].escaneados

    return false
  }

  function getZonas(rutaOperador) {
    // Obtiene la estructura de la ruta de una zona para guardar en firebase
    return Object.keys(rutaOperador)
      .map(zona => ({zona, escaneados: rutaOperador[zona].escaneados, pedidos: getPedidosOrdenadosPorDistancia(rutaOperador[zona].pedidos)}))
      .filter(zonaOperador => zonaOperador.pedidos.length > 0)
      .sort((a, b) => a.zona.localeCompare(b.zona))
  }

  function getPedidosOrdenadosPorDistancia(pedidos) {
    return [...pedidos].sort((a, b) => {
      if (a.distancia > b.distancia) return 1;
      if (a.distancia < b.distancia) return -1;
      return 0
    })
  }
</script>

<style scoped>

</style>
