<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <div @decode="onDecode" class="scanner-container">
                    <div v-show="!isLoading">
                        <video poster="data:image/gif,AAAA" ref="scanner"></video>
                        <div class="overlay-element"></div>
                        <div class="laser"></div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { BrowserMultiFormatReader, Exception } from "@zxing/library";

    export default {
        name: "Escaner",
        data() {
            return  {
                decodedText: null,
                isLoading: true,
                codeReader: new BrowserMultiFormatReader(null, 1000),
                isMediaStreamAPISupported:
                    navigator &&
                    navigator.mediaDevices &&
                    "enumerateDevices" in navigator.mediaDevices
            }
        },
        mounted() {
            if (!this.isMediaStreamAPISupported) {
                throw new Exception("Media Stream API is not supported");
            }
            this.start();
            this.$refs.scanner.oncanplay = () => {
                this.isLoading = false;
                this.$emit("loaded");
            };
        },
        beforeDestroy() {
            this.codeReader.reset();
        },
        beforeRouteLeave() {
            this.codeReader.reset();
        },
        methods: {
            onDecode (decodedMessage) {
                this.decodedText = decodedMessage
                this.mostrarPedido(this.decodedText)
            },
            start() {
                this.codeReader.decodeFromVideoDevice(undefined, this.$refs.scanner, (result) => {
                        if (result) {
                            this.onDecode(result.text);
                        }
                    }
                );
            },
            stop() {
                this.codeReader.reset();
            },
            mostrarPedido(value) {
                this.$emit('escanear', value);
            }
        }
    }
</script>

<style scoped>
    video {
        max-width: 100%;
        max-height: 100%;
    }
    .scanner-container {
        position: relative;
    }
    .overlay-element {
        position: absolute;
        top: 0;
        width: 100%;
        height: 99%;
        background: rgba(30, 30, 30, 0.5);
        -webkit-clip-path: polygon(
                0% 0%,
                0% 100%,
                20% 100%,
                20% 20%,
                80% 20%,
                80% 80%,
                20% 80%,
                20% 100%,
                100% 100%,
                100% 0%
        );
        clip-path: polygon(
                0% 0%,
                0% 100%,
                20% 100%,
                20% 20%,
                80% 20%,
                80% 80%,
                20% 80%,
                20% 100%,
                100% 100%,
                100% 0%
        );
    }
    .laser {
        width: 60%;
        margin-left: 20%;
        background-color: tomato;
        height: 1px;
        position: absolute;
        top: 40%;
        z-index: 2;
        box-shadow: 0 0 4px red;
        -webkit-animation: scanning 2s infinite;
        animation: scanning 2s infinite;
    }
    @-webkit-keyframes scanning {
        50% {
            -webkit-transform: translateY(75px);
            transform: translateY(75px);
        }
    }
    @keyframes scanning {
        50% {
            -webkit-transform: translateY(75px);
            transform: translateY(75px);
        }
    }
</style>
