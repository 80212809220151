<template>
  <wrapper>
    <v-subheader>Rutas del día
      <v-btn icon color="primary" @click="$router.go()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-subheader>

    <template v-if="ruta.zonas && ruta.zonas.length > 0">
      <v-row v-if="!esRutaCompleta" justify="center">
        <v-col v-for="(zona, index) in ruta.zonas" :key="index" cols="12" sm="10" md="8" lg="7">
          <zona-operador :zona="zona" @abrir-escaneo="abrirEscaneo(zona)" @mostrar-mapa="mostrarZonaEnMapa(zona)" :rutaSinIniciar="esRutaSinIniciar">
            <template v-slot:pedido="{ pedido }">
              <pedido-operador :pedido="pedido" :esta-escaneado="zona.escaneados" @abrir-detalles="abrirDialogDetalles"
                               @abrir-comentarios="abrirDialogComentarios" @abrir-para-cancelar="abrirDialogCancelarPedido"
                               @mostrar-mapa="mostrarPedidoEnMapa" @abrir-para-iniciar-ruta="abrirDialogIniciarRuta"
                               @abrir-pausar-entrega="pausarEntrega"
                               @abrir-para-escanear-y-entregar="abrirDialogEscanerEntrega"/>
            </template>
          </zona-operador>
        </v-col>
      </v-row>

      <full-state v-else titulo="Ha completado la ruta del día">
        <resumen-ruta-terminada :ruta="ruta"/>
      </full-state>
    </template>

    <empty-state v-else-if="!$loader" titulo="Aún no tiene una ruta asignada, contacte al administrador"/>

    <v-dialog v-model="dialogAbrirEscaneo" width="600" persistent>
      <v-card v-if="zonaParaEscanear.zona" style="border-radius: 10px">
        <v-card-title>Escaneo de pedidos de {{ $getZonaNombre(zonaParaEscanear.zona) }}</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <h3 class="primary--text">
                Pedidos escaneados: {{ pedidosZonaEscaneados }}/{{ zonaParaEscanear.pedidos.length }}
              </h3>
            </v-col>
            <v-col v-if="ultimoPedidoEscaneado" cols="12" class="text-center">
              <h5 class="text--disabled">Último escaneado: {{ ultimoPedidoEscaneado }}</h5>
            </v-col>
            <v-col v-if="dialogAbrirEscaneo" cols="12">
              <escaner @escanear="marcarPedidoEscaneado"/>
            </v-col>
            <v-col v-if="habilitaConfirmarEscaneo" cols="12" class="text-center">
              <v-btn color="primary" rounded @click="confirmarEscaneo">Confirmar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn text plain @click="dialogAbrirEscaneo = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogo v-model="dialogMapaPedidos" :title="mapaInfo.titulo" es-consulta ancho="700">
      <v-row>
        <v-col cols="12">
          <mapa :pedidos="mapaInfo.pedidos" class="mapa"/>
        </v-col>
        <v-col v-if="mapaInfo.linkMaps" cols="12" class="text-center">
          <v-btn outlined color="primary" rounded :href="mapaInfo.linkMaps" target="_blank">
            Abrir en Google maps
          </v-btn>
        </v-col>
      </v-row>
    </dialogo>

    <v-dialog v-model="dialogEscaneoParaEntrega" width="600">
      <v-card v-if="pedidoParaEntregar.id" style="border-radius: 10px">
        <v-card-title>
          <span>Escanee el pedido {{ pedidoParaEntregar.id }} para entregar</span>
        </v-card-title>

        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="10" lg="8">
              <v-text-field v-model="pedidoParaEntregar.responsable" label="Responsable de recibir" color="primary"
                            autofocus :rules="responsableRules" />
            </v-col>
            <v-col v-if="dialogEscaneoParaEntrega && pedidoParaEntregar.responsable" cols="12">
              <escaner @escanear="marcarPedidoEntregado"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn text plain @click="dialogEscaneoParaEntrega = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogComentarios" width="600" scrollable :fullscreen="$vuetify.breakpoint.smAndDown" persistent>
      <v-card v-if="pedidoComentarios">
        <v-card-title>
          Comentarios de pedido {{ pedidoComentarios.id }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="pedidoComentarios">
            <template v-if="pedidoComentarios.comentarios.length > 0">
              <v-col v-for="(comentario, indexComentario) in pedidoComentarios.comentarios" :key="indexComentario"
                     cols="12">
                <v-card rounded outlined>
                  <v-card-text>
                    {{ comentario }}
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="text-center">
                <v-card outlined class="my-4">
                  <v-card-text>
                    <h4>Aún no hay comentarios</h4>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-text-field v-model="comentario" :append-outer-icon="comentario ? 'mdi-send' : ''" filled
                        clear-icon="mdi-close-circle" clearable autofocus placeholder="Escribir comentario..."
                        @click:append-outer="comentario ? enviarComentario() : ''"
                        @keyup.enter="comentario ? enviarComentario() : ''"/>
        </v-card-actions>
        <v-card-actions>
          <v-spacer/>
          <v-btn text plain @click="dialogComentarios = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogo v-model="dialogDetalles" title="Detalles de pedido">
      <v-card v-if="detallesPedido.id" outlined>
        <v-list-item>
          <v-list-item-content>
            <div class="d-block">{{ detallesPedido.cliente.nombre }}</div>
            <v-list-item-subtitle>{{ detallesPedido.cliente.email }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ detallesPedido.cliente.telefono }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>{{ detallesPedido.cliente.direccionCompleta }}</v-card-text>
        <v-card-text class="font-italic">{{ detallesPedido.cliente.referencias }}</v-card-text>
      </v-card>
    </dialogo>

    <v-overlay v-if="ruta.zonas && ruta.zonas.length > 0 && esRutaSinIniciar" :value="dialogVistaPrevia" z-index="6">
      <v-row v-if="!mostrarMapaVistaPrevia">
        <v-col cols="12" class="text-center">
          <v-card>
            <v-card-text>
              <h1>Bienvenido {{ $usuario.nombre }}</h1>
            </v-card-text>
            <v-card-text>
              <v-card style="border-radius: 20px; cursor: pointer" class="mx-6"
                      flat @click="mostrarMapaVistaPrevia = true">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-icon class="primary--text" size="40">mdi-google-maps</v-icon>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <h3 class="primary--text">Vista previa de la ruta</h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" class="text-center" style="display: flex;justify-content: center;align-items: center;">
          <v-card :width="$vuetify.breakpoint.smAndDown ? '350' : '600'">
            <v-card-text>
              <h2 class="text-center">
                Vista previa de la ruta del día
              </h2>
            </v-card-text>

            <v-card-text>
              <mapa :pedidos="pedidosRuta" class="mapa"/>
              <v-btn rounded color="primary" class="mt-3" @click="dialogVistaPrevia = false">Iniciar despacho</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>

    <dialogo v-model="dialogCancelarPedido"
             :title="pedidoParaCancelar && pedidoParaCancelar.id ? '¿Desea cancelar la ruta del pedido ' + pedidoParaCancelar.id + '?' : ''">
      <v-text-field v-model="pedidoParaCancelar.motivoCancelacion" autofocus label="Motivo" persistent-hint
                    hint="Ingrese el motivo de cancelación para continuar" />
      <template slot="actions">
        <v-btn text color="primary" plain :disabled="!pedidoParaCancelar.motivoCancelacion" @click="enviarMotivoCancelacion">
          Aceptar
        </v-btn>
      </template>
    </dialogo>
  </wrapper>
</template>

<script>
  import Escaner from "../Escaneo/Escaner";
  import RutasService from "../../services/RutasService";
  import Mapa from "../Mapa/Mapa";
  import EmptyState from "../General/EmptyState";
  import PedidosService from "../../services/PedidosService";
  import FullState from "../General/FullState";
  import ZonaOperador from "../Zonas/ZonaOperador";
  import ResumenRutaTerminada from "../Rutas/ResumenRutaTerminada";
  import PedidoOperador from "../Pedidos/PedidoOperador";

  export default {
    name: "Usuario",
    components: {PedidoOperador, ResumenRutaTerminada, ZonaOperador, FullState, Mapa, Escaner, EmptyState},
    data() {
      return {
        ruta: {},
        dialogAbrirEscaneo: false,
        dialogMapaPedidos: false,
        mapaInfo: {titulo: 'Mapa', pedidos: []},
        zonaParaEscanear: {},
        mostrarEscaneo: false,
        ultimoPedidoEscaneado: null,
        dialogEscaneoParaEntrega: false,
        pedidoParaEntregar: {},
        dialogComentarios: false,
        comentario: null,
        pedidoComentarios: null,
        detallesPedido: {},
        dialogDetalles: false,
        habilitaConfirmarEscaneo: false,
        dialogVistaPrevia: true,
        mostrarMapaVistaPrevia: false,
        responsableRules: [v => !!v || 'Este campo es requerido'],
        dialogCancelarPedido: false,
        pedidoParaCancelar: {}
      }
    },
    async created() {
      this.$loader = true;

      try {
        this.$rutas = await RutasService.getRutasDesdeOperador();
        if (this.$rutas.id) {
          this.ruta = await RutasService.getZonasOperador(this.$rutas.id, this.$usuario.id);
          if (this.ruta.zonas.length <= 0)
            this.$showAlerta('600', 'warning', 'mdi-alert', 'Aún no tiene una ruta asignada')
        } else {
          this.$showAlerta('600', 'warning', 'mdi-alert', 'Aún no ha iniciado la hora para empezar la ruta')
        }
      } catch (e) {
        console.log(e)
      }

      this.$loader = false;
    },
    watch: {
      dialogMapaPedidos(val) {
        if (!val) this.mapaInfo = {titulo: 'Mapa', pedidos: []}
      }
    },
    computed: {
      pedidosZonaEscaneados() {
        if (this.zonaParaEscanear.pedidos) {
          return (this.zonaParaEscanear.pedidos.filter(pedido => pedido.escaneado)).length;
        }
        return 0;
      },
      pedidosRuta () {
        return RutasService.getPedidosRuta(this.ruta)
      },
      esRutaCompleta() {
        return this.pedidosRuta.every(pedido => pedido.estatus === 'Entregada' || pedido.estatus === 'Entrega cancelada');
      },
      esRutaSinIniciar() {
          return this.pedidosRuta.every(pedido => pedido.estatus === 'Por enviar');
      }
    },
    methods: {
      mostrarPedidoEnMapa(pedido) {
        const titulo = 'Pedido ' + pedido.id;
        const linkMaps = 'https://maps.google.com/?q=' + pedido.cliente.latitud + ',' + pedido.cliente.longitud;
        this.mapaInfo = {titulo, pedidos: [pedido], linkMaps};
        this.dialogMapaPedidos = true;
      },
      mostrarZonaEnMapa(zona) {
        const titulo = this.$getZonaNombre(zona.zona);
        this.mapaInfo = {titulo, pedidos: zona.pedidos};
        this.dialogMapaPedidos = true;
      },
      abrirEscaneo(zona) {
        this.zonaParaEscanear = JSON.parse(JSON.stringify(zona));
        this.ultimoPedidoEscaneado = null;
        this.habilitaConfirmarEscaneo = false;
        this.dialogAbrirEscaneo = true;
      },
      marcarPedidoEscaneado(event) {
        this.ultimoPedidoEscaneado = event

        try {
          let pedidos = this.zonaParaEscanear.pedidos
          let index = pedidos.findIndex(pedido => pedido.id === Number(event));

          if (pedidos[index].escaneado) {
            this.$showAlerta('600', 'info', 'mdi-alert-circle', 'Este pedido ya está escaneado', true)
          } else {
            this.$set(pedidos[index], 'escaneado', true);
          }
        } catch (e) {
          this.$showAlerta('600', 'warning', 'mdi-alert', 'Este pedido no se encuentra', true)
        }

        this.$nextTick(() => {
          if (this.pedidosZonaEscaneados === this.zonaParaEscanear.pedidos.length) {
            this.habilitaConfirmarEscaneo = true;
          }
        });
      },
      confirmarEscaneo() {
        this.dialogAbrirEscaneo = false;
        guardarZonaEscaneada(this);
      },
      async cambiarEstatusPedido(pedido, estatus, callbackSuccess) {
        let vm = this
        vm.$loader = true;
        vm.$alerta.model = false
        vm.$set(pedido, 'estatus', estatus)

        try {
          await PedidosService.updateEstatusEnVIS(pedido.id, estatus)
          await RutasService.updateZonasOperador(vm.$rutas.id, vm.ruta)
          if (!!callbackSuccess) {
            callbackSuccess();
          }
        } catch (e) {
          vm.$showAlerta('600', 'error', 'mdi-alert-circle',
            'No fue posible cambiar el estatus del pedido, por favor inténtelo de nuevo más tarde.', true);
          vm.$router.go();
        }
        vm.$loader = false;
      },
      abrirDialogIniciarRuta(pedido) {
        if (hayUnaZonaSinEscanear(this.ruta.zonas))
          this.$showAlerta('600', 'warning', 'mdi-alert', 'Aún hay zonas sin escanear.', true);

        else if (hayUnPedidoEnRuta(this.pedidosRuta))
          this.$showAlerta('600', 'warning', 'mdi-alert', 'Ya existe un pedido en ruta.', true);

        else
          this.$showAlerta('600', 'info', 'mdi-help-circle',
            '¿Desea iniciar la ruta del pedido ' + pedido.id + '?', false,
            () => this.cambiarEstatusPedido(pedido, 'En ruta'), require('../../assets/camioneta-iniciar-ruta.svg'));
      },
      abrirDialogCancelarPedido(pedido) {
        this.pedidoParaCancelar = pedido
        this.$delete(this.pedidoParaCancelar, 'motivoCancelacion');
        this.dialogCancelarPedido = true
      },
      abrirDialogEscanerEntrega(pedido) {
        this.pedidoParaEntregar = pedido
        this.dialogEscaneoParaEntrega = true
      },
      marcarPedidoEntregado(event) {
        if (this.pedidoParaEntregar.responsable) {
          if (this.pedidoParaEntregar.id === Number(event)) {
            this.dialogEscaneoParaEntrega = false
            this.cambiarEstatusPedido(this.pedidoParaEntregar, 'Entregada',
              () => (this.$showAlerta('600', 'success', 'mdi-check-circle', 'Se escaneó correctamente el pedido', true)))
          } else {
            this.$showAlerta('600', 'warning', 'mdi-alert', 'El número de pedido no coincide', true)
          }
        } else {
          this.$showAlerta('600', 'warning', 'mdi-alert', 'Ingrese el responsable de recibir', true)
        }
      },
      abrirDialogComentarios(pedido) {
        this.pedidoComentarios = pedido
        this.dialogComentarios = true
      },
      async enviarComentario() {
        this.$loader = true;
        try {
          this.pedidoComentarios.comentarios.push(this.comentario)
          await RutasService.updateZonasOperador(this.$rutas.id, this.ruta)
        } catch (e) {
          this.$showAlerta('600', 'error', 'mdi-alert-circle',
            'No fue posible guardar el comentario, por favor inténtelo de nuevo más tarde.', true);
          this.$router.go();
        }
        this.$loader = false;
        this.comentario = null
      },
      abrirDialogDetalles(pedido) {
        this.detallesPedido = pedido
        this.dialogDetalles = true
      },
      pausarEntrega(pedido) {
        this.$showAlerta('600', 'warning', 'mdi-alert', '¿Desea pausar la ruta del pedido ' + pedido.id + '?',
          false, () => this.cambiarEstatusPedido(pedido, 'Entrega programada'))
      },
      enviarMotivoCancelacion() {
        // Al cambiar el estatus en firebase, se guardará también el motivo de cancelación.
        this.cambiarEstatusPedido(this.pedidoParaCancelar, 'Entrega cancelada',
            () => {
              this.$showAlerta('600', 'info', 'mdi-check-circle', 'Pedido cancelado.', true)
              this.pedidoParaCancelar = {}
              this.dialogCancelarPedido = false
            })
      },
    }
  }

  async function guardarZonaEscaneada(vm) {
    vm.$loader = true;

    let indexZona = vm.$getIndex(vm.ruta.zonas, 'zona', vm.zonaParaEscanear);
    let zonasParaGuardar = JSON.parse(JSON.stringify(vm.ruta));

    try {
      await marcarZonaParaEntregaProgramada(zonasParaGuardar.zonas[indexZona]);

      await RutasService.updateZonasOperador(vm.$rutas.id, zonasParaGuardar)
      vm.ruta = zonasParaGuardar;
      vm.$showAlerta('600', 'info', 'mdi-alert-circle', 'Los pedidos de esta zona están escaneados', true)
    } catch (e) {
      vm.$showAlerta('600', 'error', 'mdi-alert-circle',
        'Ha ocurrido un error, por favor inténtelo de nuevo más tarde.', true);
      vm.$router.go();
    }
    vm.$loader = false;
  }

  async function marcarZonaParaEntregaProgramada(zona) {
    zona.escaneados = true;
    for (let pedido of zona.pedidos) {
      pedido.estatus = 'Entrega programada';
      await PedidosService.updateEstatusEnVIS(pedido.id, 'Entrega programada');
    }
  }

  function hayUnPedidoEnRuta(pedidosRuta) {
    return pedidosRuta.some(pedido => pedido.estatus === 'En ruta');
  }

  function hayUnaZonaSinEscanear(zonas) {
    return zonas.some(zona => !zona.escaneados);
  }
</script>

<style>
  .theme--dark.v-bottom-navigation {
    background-color: #2E2E2E00;
    color: #FFFFFF;
  }

  .v-item-group.v-bottom-navigation {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    justify-content: end;
  }
</style>
