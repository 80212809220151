<template>
  <v-card style="border-radius: 40px;" elevation="0" class="my-4 py-3">
    <foto :id="operador.id" type="usuario" updatable/>
    <v-card-title :class="operador.nombre.length < 28 ? 'subtitle-2' : 'caption font-weight-bold'">
      {{ operador.nombre }}
      <v-btn v-if="$usuario.administrador" icon x-small color="primary" @click="$abrirDialogOperador(operador)">
        <v-icon>mdi-lead-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="pb-0 pt-0" style="height: 22px;">
          <span>{{ operador.zonaPredeterminada ? $getZonaNombre(operador.zonaPredeterminada) : '' }}</span>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <span>{{ operador.email }}</span>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <span>{{ operador.telefono ? operador.telefono : 'Sin télefono' }}</span>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0">
          <h4 v-if="operador.idUnidad">
            {{ operador.unidad | unidad }}
            <v-btn v-if="$usuario.administrador" icon x-small color="primary" @click="abrirDialogAsignarUnidad(operador)">
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
          </h4>
          <h4 v-else>Sin unidad asignada</h4>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-3" style="display: flex;justify-content: center;align-items: center;">
      <v-bottom-navigation width="300px" class="ma-0 pa-0 text-center"
                           style="display: flex;justify-content: center;align-items: center;">
        <v-btn v-if="!operador.idUnidad && $usuario.administrador" x-small @click="abrirDialogAsignarUnidad(operador)">
          <small class="text--disabled caption">Asignar unidad</small>
          <v-icon class="text--disabled">mdi-car-estate</v-icon>
        </v-btn>
        <template v-else>
          <v-btn v-if="hayDisponibilidadParaAsignarRuta && $usuario.administrador" x-small @click="dialogAsignarRuta = true">
            <template v-if="zonasOperador.zonas && zonasOperador.zonas.length > 0">
              <small class="text--disabled caption">Editar ruta</small>
              <v-icon class="text--disabled">mdi-cached</v-icon>
            </template>
            <template v-else>
              <small class="text--disabled caption">Asignar ruta</small>
              <v-icon class="text--disabled">mdi-android-auto</v-icon>
            </template>
          </v-btn>

          <v-btn v-if="zonasOperador.zonas && zonasOperador.zonas.length > 0" x-small @click="dialogMapa = true">
            <small class="primary--text caption">Ver seguimiento</small>
            <v-icon class="primary--text">mdi-progress-alert</v-icon>
          </v-btn>
        </template>
      </v-bottom-navigation>
    </v-card-actions>

    <v-dialog v-model="dialogMapa" width="700" persistent scrollable :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card>
        <v-card-title>
          <v-btn icon color="gray" class="mr-2" @click="dialogMapa = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          Seguimiento
          <v-spacer />
          <contador-pedidos :pedidos="pedidosDeTodasLasZonas" />
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <mapa :pedidos="pedidosDeTodasLasZonas" class="mapa"/>
            </v-col>
            <template v-for="(zona, index) in zonasOperador.zonas">
              <v-subheader :key="'nombre-zona-' + index">{{ $getZonaNombre( zona.zona ) }}</v-subheader>
              <v-col v-for="(pedido, indexPedido) in zona.pedidos" :key="'zona-' + index + '-' + indexPedido" cols="12">
                <v-card class="pa-1">
                  <v-card-title class="my-0 py-0">
                    Pedido: {{pedido.id}}
                    <v-spacer/>
                    <v-menu bottom origin="center center" transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                      </template>
                      <v-list>
                        <v-list-item :disabled="pedido.estatus !== 'Entrega programada'" @click="abrirDialogCancelarPedido(pedido)">
                          <v-list-item-title>Cancelar entrega</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>

                  <v-card-subtitle class="my-0 py-0">
                    <h4 class="font-weight-regular caption">{{ pedido.cliente.colonia + ', ' + pedido.cliente.municipio }}</h4>
                    <h4 v-if="pedido.responsable" class="font-weight-regular caption">Responsable de entrega: {{ pedido.responsable }}</h4>
                    <h4 v-if="pedido.motivoCancelacion" class="font-weight-regular caption">Motivo de cancelación: {{ pedido.motivoCancelacion }}</h4>
                  </v-card-subtitle>
                  <v-card-actions class="mt-0 pt-1 mx-2">
                    <chip-estatus :estatus="pedido.estatus"/>
                    <v-spacer/>

                    <v-badge v-if="pedido.comentarios.length > 0" :content="pedido.comentarios.length" color="green"
                             overlap>
                      <v-btn icon color="primary" @click="abrirDialogComentarios(pedido)">
                        <v-icon>mdi-message-text</v-icon>
                      </v-btn>
                    </v-badge>

                    <v-btn v-if="pedido.estatus === 'Entrega cancelada'" class="ml-4" color="primary" icon
                           @click="abrirDialogEscanearPedido(pedido)">
                      <v-icon>mdi-qrcode-scan</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <dialogo v-model="dialogAsignarUnidad" title="Asignar Unidad">
      <v-radio-group v-model="$operadorParaGuardar.idUnidad">
        <v-card v-for="(unidad, indexUnidad) in $flotilla" :key="indexUnidad" outlined class="mb-5 px-1">
          <v-radio :value="unidad.id" class="ml-2">
            <template v-slot:label>
              <unidad :unidad="unidad" sin-foto/>
            </template>
          </v-radio>
        </v-card>
      </v-radio-group>
      <template slot="actions">
        <v-btn text color="primary" plain @click="guardarUnidad">Aceptar</v-btn>
      </template>
    </dialogo>

    <dialogo v-model="dialogAsignarRuta" title="Asignar ruta">
      <v-row>
        <v-col cols="12" sm="12">
          <asignacion-ruta v-if="dialogAsignarRuta && (zonasConPedidosDisponibles.length > 0 ||
          (zonasOperadorNuevas.zonas && zonasOperadorNuevas.zonas.length > 0))"
                           v-model="zonasOperadorNuevas.zonas"
                           :zonas-con-pedidos-disponibles="zonasConPedidosDisponibles"/>
        </v-col>
      </v-row>
      <template slot="actions">
        <v-btn text color="primary" plain :disabled="zonasOperadorNuevas.zonas.length <= 0" @click="asignarRuta">
          Aceptar
        </v-btn>
      </template>
    </dialogo>

    <v-dialog v-model="dialogRecibirPedido" width="700">
      <v-card v-if="pedidoParaDevolver" style="border-radius: 10px">
        <v-card-title>
          <span>Escanee el pedido {{ pedidoParaDevolver.id }} para devolución</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col v-if="dialogRecibirPedido" cols="12">
              <escaner @escanear="marcarPedidoDevuelto"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn text plain @click="dialogRecibirPedido = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogo v-model="dialogoComentarios" :title="pedidoComentarios ? 'Comentarios de pedido ' + pedidoComentarios.id : ''">
      <v-row v-if="pedidoComentarios">
        <v-col v-for="(comentario, indexComentario) in pedidoComentarios.comentarios" :key="indexComentario" cols="12">
          <v-card rounded outlined>
            <v-card-text>{{ comentario }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </dialogo>
  </v-card>
</template>

<script>
  import Mapa from "../Mapa/Mapa";
  import OperadoresService from "../../services/OperadoresService";
  import AsignacionRuta from "../Zonas/AsignacionRuta";
  import PedidosService from "../../services/PedidosService";
  import RutasService from "../../services/RutasService";
  import FechaService from "../../services/FechaService";
  import ChipEstatus from "../Pedidos/ChipEstatus";
  import Escaner from "../Escaneo/Escaner";
  import Foto from "../General/Foto";
  import Unidad from "../Flotilla/Unidad";
  import ContadorPedidos from "../Pedidos/ContadorPedidos";

  export default {
    name: "Operador",
    components: {ContadorPedidos, Unidad, Foto, Escaner, ChipEstatus, AsignacionRuta, Mapa},
    props: {
      operador: {type: Object, required: true},
      zonasConPedidosDisponibles: {type: Array, required: true},
      zonasOperadorAsignadas: {type: Object, required: true},
      fecha: {type: String, default: FechaService.getFechaString()}
    },
    data() {
      return {
        zonasOperador: JSON.parse(JSON.stringify(this.zonasOperadorAsignadas)),
        zonasOperadorNuevas: JSON.parse(JSON.stringify(this.zonasOperadorAsignadas)),
        dialogMapa: false,
        dialogAsignarUnidad: false,
        dialogAsignarRuta: false,
        dialogRecibirPedido: false,
        pedidoParaDevolver: null,
        dialogoComentarios: false,
        pedidoComentarios: null,
        hayDisponibilidadParaAsignarRuta: !FechaService.enRangoDentroDeFechaLimite(this.fecha, -1)
      }
    },
    watch: {
      dialogAsignarRuta() {
        this.zonasOperadorNuevas = JSON.parse(JSON.stringify(this.zonasOperadorAsignadas))
      },
      zonasOperadorAsignadas(val) {
        this.zonasOperador = JSON.parse(JSON.stringify(val));
      }
    },
    computed: {
      pedidosDeTodasLasZonas() {
        return RutasService.getPedidosRuta(this.zonasOperador);
      }
    },
    methods: {
      abrirDialogAsignarUnidad(operador) {
        this.$operadorParaGuardar = JSON.parse(JSON.stringify(operador))
        this.dialogAsignarUnidad = true
      },
      async guardarUnidad() {
        this.$loader = true;
        try {
          let operadorEditado = await OperadoresService.updateOperador(this.$operadorParaGuardar)
          let index = this.$getIndex(this.$operadores, 'id', operadorEditado)
          this.$set(this.$operadores, index, operadorEditado)
        } catch (e) {
          this.$showAlerta('600', 'error', 'mdi-alert-circle',
            'No fue posible cambiar la unidad, por favor inténtelo de nuevo más tarde.', true);
        }
        this.$loader = false;
        this.dialogAsignarUnidad = false
      },
      asignarRuta() {
        let vm = this;
        vm.$loader = true;
        vm.zonasOperadorNuevas.unidad = vm.operador.unidad
        RutasService.updateZonasOperador(vm.$rutas.id, vm.zonasOperadorNuevas)
          .then(() => {
            asignarRutaParaOperador(vm, vm.$rutas.operadores, vm.zonasOperadorNuevas);
            vm.zonasOperador = JSON.parse(JSON.stringify(vm.zonasOperadorNuevas));
            vm.$emit('guardar-ruta', vm.zonasOperadorNuevas.zonas);
          })
          .catch(() => {
            this.$showAlerta('600', 'error', 'mdi-alert-circle',
              'No fue posible asignar la ruta, por favor inténtelo de nuevo más tarde.', true);
          })
          .finally(() => {
            vm.dialogAsignarRuta = false;
            vm.$loader = false;
          })
      },
      abrirDialogEscanearPedido(pedido) {
        this.pedidoParaDevolver = pedido
        this.dialogRecibirPedido = true
      },
      async marcarPedidoDevuelto(event) {
        if (this.pedidoParaDevolver.id === Number(event)) {
          try {
            this.pedidoParaDevolver.estatus = 'Entrega reprogramada'
            await PedidosService.updateEstatusEnVIS(this.pedidoParaDevolver.id, 'Por enviar')
            await RutasService.updateZonasOperador(this.$rutas.id, this.zonasOperador)
            this.$showAlerta('600', 'success', 'mdi-check-circle', '¡Pedido devuelto exitosamente!', true);
          } catch (e) {
            this.$showAlerta('600', 'error', 'mdi-alert-circle',
              'No fue posible cambiar el estatus del pedido, por favor inténtelo de nuevo más tarde.', true);
          }
          this.dialogRecibirPedido = false
        } else {
          this.$showAlerta('600', 'warning', 'mdi-alert', 'El número de pedido no coincide.', true)
        }
      },
      abrirDialogComentarios(pedido) {
        this.pedidoComentarios = pedido
        this.dialogoComentarios = true
      },
      abrirDialogCancelarPedido(pedido) {
        this.$showAlerta('600', 'warning', 'mdi-alert', '¿Desea cancelar la ruta del pedido ' + pedido.id + '?',
          false, () => this.cancelarEntregaPedido(pedido))
      },
      async cancelarEntregaPedido(pedido) {
        this.$loader = true;
        this.$alerta.model = false

        try {
          this.$set(pedido, 'estatus', 'Entrega cancelada')
          await PedidosService.updateEstatusEnVIS(pedido.id, 'Entrega cancelada')
          await RutasService.updateZonasOperador(this.$rutas.id, this.zonasOperador)
        } catch (e) {
          this.$showAlerta('600', 'error', 'mdi-alert-circle',
            'No fue posible cambiar el estatus del pedido, por favor inténtelo de nuevo más tarde.', true);
          this.$router.go();
        }
        this.$loader = false;
      }
    }
  }

  function asignarRutaParaOperador(vm, operadores, zonasOperador) {
    let index = operadores.findIndex(operador => operador.id === zonasOperador.id)
    if (index >= 0)
      vm.$set(operadores, index, zonasOperador)
    else
      operadores.push(zonasOperador)
  }
</script>

<style scoped>

</style>
