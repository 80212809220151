import AuthConst from "../../src/config/AuthConst";
import PedidosService from "./PedidosService";

const getMarker = (pedido) => {
  return {
    position: { lat: Number(pedido.cliente.latitud), lng: Number(pedido.cliente.longitud) },
    title: 'Pedido ' + pedido.id,
    color: PedidosService.getColorEstatus(pedido.estatus)
  };
}

const getCentro = (pedidos) => {
  let sumPosition = pedidos.reduce((sumPosition, pedido) =>
    ({ lat: sumPosition.lat + Number(pedido.cliente.latitud), lng: sumPosition.lng + Number(pedido.cliente.longitud) }),
    { lat: 0, lng: 0 }
  )

  return { lat: sumPosition.lat / pedidos.length, lng: sumPosition.lng / pedidos.length }
}

const getDistancia = (posicion, posicionAlmacen = AuthConst.COORDENADAS_VJP_ALMACEN) => {
  const R = 6371; // km
  const dLat = getRad(posicionAlmacen.lat - posicion.lat);
  const dLng = getRad(posicionAlmacen.lng - posicion.lng);
  const radLat = getRad(posicion.lat);
  const radLatAlmacen = getRad(posicionAlmacen.lat);

  const a = getSin(dLat) * getSin(dLat) + getSin(dLng) * getSin(dLng) * Math.cos(radLat) * Math.cos(radLatAlmacen);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const distancia = R * c;
  return Number(Math.round(distancia + 'e14') + 'e-14'); // Redondeo 14 dígitos
}

function getRad(value) {
  return value * Math.PI / 180;
}

function getSin(value) {
  return Math.sin(value/2);
}

export default { getMarker, getCentro, getDistancia }
