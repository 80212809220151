<template>
    <v-row justify="center" align="center" class="mt-4">
        <v-col cols="12" sm="12" class="text-center">
            <h1 class="grey--text">{{ titulo }}</h1>
        </v-col>
        <v-col cols="12" sm="12" class="text-center">
            <v-img :src="require('../../assets/no_search_results.png')"/>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "EmptyState",
        props: {
            titulo: { type: String, required: true } },
    }
</script>

<style scoped>

</style>
